import {css, html, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {AuthRedirect} from '../auth/authRedirect';
import {AuthGuard} from '../auth/authGuard';
import {Router} from '@vaadin/router';

@customElement('login-panel')
export class LoginPanel extends LitElement {
  static override styles = css`
    :host {
      display: flex;
      height: 100vh;
      background-color: var(--white);
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 67%;
      min-height: 100vh;
    }

    .header {
      align-self: flex-start;
      padding: 1rem 2rem;
      width: 93px;
      height: 20px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-self: center;
      width: 90%;
      padding: 1rem 2rem;
    }

    .footer-text {
      font-size: 14px;
      opacity: 80%;
      color: var(--gray-300);
    }

    .footer-right {
      display: flex;
      gap: 20px;
    }

    .login-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }

    sl-button.login-button::part(base) {
      background-color: var(--orange-300);
      color: var(--white);
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 16px;
      width: 400px;
      height: 48px;
      margin-top: 32px;
    }

    sl-button.login-button::part(base):hover {
      background-color: var(--orange-400);
    }

    .title {
      font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
      font-weight: 500;
      font-size: 24px;
      color: var(--gray-300);
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .image-container {
      width: 42%;
      height: 100vh;
      background-image: url('/static/images/northell-watch.png');
      background-size: cover;
      background-position: center;
    }
  `;

  override async connectedCallback(): Promise<void> {
    super.connectedCallback();
    if (AuthGuard.isAuthenticated()) {
      Router.go('/chat');
    }
  }

  override render() {
    return html`
      <div class="container">
        <div class="header">
          <img src="../../static/images/northell-logo.svg" alt="Northell Logo" />
        </div>
        <div class="login-container">
          <div class="title">Unlock Top Learning</div>
          <sl-button class="login-button" variant="default" size="medium" @click="${this.login}"
            >Login</sl-button
          >
        </div>
        <div class="footer">
          <span class="footer-text">
            Copyright 2004 - 2023 Northell Partners Ltd. All Rights Reserved.
          </span>
          <div class="footer-text footer-right">
            <span>Help</span>
            <span>Terms</span>
            <span>Privacy Policy</span>
          </div>
        </div>
      </div>
      <div class="image-container"></div>
    `;
  }

  private login() {
    AuthRedirect.login();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'login-panel': LoginPanel;
  }
}
